.user-menu-details {
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	margin-bottom: 6px;
	border-bottom: 1px solid #e9e9e9;

	.user-menu-details-initials {
		border: 1px solid rgb(179, 179, 179);
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 14px;
		margin-inline-end: 10px;
		font-weight: bold;
	}

	.user-menu-details-content {
		.user-menu-name-container {
			display: flex;
			align-items: center;

			.country-flag {
				width: 22px;
				margin-left: 4px;
			}
		}

		p {
			color: #0f2147;
			margin: 0;
			line-height: 1;

			&.name {
				font-weight: 700;
			}

			&.email {
				font-size: 12px;
			}
		}
	}
}
