.order-state-timer-container {
	display: flex;
	align-items: start;

	@media (max-width: 700px) {
		justify-content: space-between;
	}

	.state-timer-row {
		display: flex;

		.support-container {
			margin-right: 8px;

			.contact-support-button {
				padding: 0;
				display: flex;
				border: none;
				background: none;
				border-radius: 50%;
				cursor: pointer;

				&:hover {
					box-shadow: 0 0 10px 4px #2b5b7d;
				}

				img {
					width: 36px;
					height: 36px;
				}
			}
		}
	}

	.state-timer-container {
		display: flex;
		flex-direction: column;

		@media (min-width: 700px) {
			align-items: end;
		}

		.timer-container {
			display: flex;
			align-items: center;

			span {
				background: linear-gradient(#00afff, #0077ff);
				color: #ffffff;
				border-radius: 4px;
				width: 32px;
				height: 34px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 4px;
				font-weight: 500;
			}
		}
	}
}
