.body-details-wrapper {
	width: 100%;
	max-width: 350px;
	padding-bottom: 50px;
	@media (max-width: 760px) {
		max-width: unset;
	}

	@media (max-width: 575px) {
		min-width: 100%;
	}

	.responsive-order-details-wrapper {
		border-bottom: 1px solid #dbdbdb;
		margin-bottom: 16px;
		padding: 0 0 8px 0;
		.details-container {
			border-left: 2px dotted rgb(196, 193, 193);
			&:dir(rtl) {
				border-right: 2px dotted rgb(196, 193, 193);
				border-left: unset;
			}
		}
		.uploadedFile {
			border: 1px solid #018aff;
			width: 125px;
			height: 30px;
			background-color: #eaecef;
			color: #018aff;
			cursor: pointer;
			padding: 1px 5px;
		}
		.order-details-title {
			padding-inline: 20px;
			&::before {
				content: '';
				position: absolute;
				margin-top: 10px;
				transform: translate(-50%, -50%);
				left: 33px;
				width: 20px; /* Diameter of the circle */
				height: 20px; /* Diameter of the circle */
				border-radius: 50%;
				display: block;
				background-color: #00afff;
			}
			&:dir(rtl)::before {
				right: 15px;
				left: unset;
			}
			&:last-child::before {
				margin-top: 15px;
			}
		}
		.details-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 4px;

			.value {
				font-weight: 700;
			}
		}
	}
}
