.order-life-cycle-page {
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
		margin: 0;
	}
}
