@media (min-width: 993px) {
	.small-screen-row {
		display: none;
	}
}

@media (min-width: 993px) {
	.table-col {
		flex-direction: row;
	}
}

@media (max-width: 400px) {
	.table-col {
		flex-flow: column-reverse;
	}
}
