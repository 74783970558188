.explain-container {
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	img {
		width: 51px;
		height: 51px;
		margin-right: 16px;
	}

	.explain-label {
		font-weight: 700;
	}
}
