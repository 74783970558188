.ant-dropdown-menu-item {
	.ant-card-bordered {
		min-width: fit-content;
	}
}
.ant-dropdown-menu-item .notifications-feed-avatar-badge {
	position: absolute;
	bottom: -6px !important;
	right: 9px !important;
	color: white;
	border-radius: 50%;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.notifications-dropdown {
	background-color: white;
	border-radius: 10px;
	position: absolute;
	padding: 0 10px;
	z-index: 999999;
	top: 11vh;
	right: 5vh;
	box-shadow:
		rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px,
		rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px,
		rgba(0, 0, 0, 0.09) 0px -3px 5px !important;

	@media (min-width: 768px) {
		top: 7vh !important;
	}
}
.ant-dropdown-menu-item .notifications-feed-green-badge {
	background: #4caf50;
}
.ant-dropdown-menu-item .notifications-feed-info-badge {
	background: #2196f3;
}
.ant-dropdown-menu-item .notifications-feed-warning-badge {
	background: #e30886;
}
.ant-col .notification-message {
	margin: 0;
}
.ant-col .notification-timestamp {
	margin: 0;
	font-size: 0.8rem; /* Adjust the font size as needed */
	color: #888; /* Light grey color */
}
.view-more-notifications-card {
	border: none;
	.ant-card-body {
		text-align: center;
		padding: 0.75rem 0 !important;
	}
}
.no-notifications-card {
	min-height: 50vh;
	border: none !important;
	.ant-card-body {
		padding: 0 !important;
	}
}
.notification-card {
	.ant-dropdown-menu-item {
		padding: 0 !important;
	}
	.ant-card-body {
		padding: 0.75rem 0.1rem !important;
	}
	border: none !important;
}
.notification-card .notifications-feed-avatar-badge {
	position: absolute;
	bottom: -6px !important;
	right: -6px !important;
	color: white;
	border-radius: 50%;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notification-card .notifications-feed-green-badge {
	background: #4caf50;
}
.notification-card .notifications-feed-info-badge {
	background: #2196f3;
}
.notification-card .notifications-feed-warning-badge {
	background: #e30886;
}
.bill-container {
	.bg-notification-blue {
		background-color: #00afff;
	}

	.ant-dropdown-menu-item {
		padding: 0 !important;
		border-style: none;
		border-width: 0px;
	}

	.notifications-menu {
		padding: 0 !important;
	}

	.notifications-menu-item {
		padding: 0 !important;
	}

	.notification-card {
		.ant-card-body {
			padding: 0.75rem !important;
		}
	}
	.avatar-badge {
		position: absolute;
		bottom: -8px;
		right: -8px;
		color: white;
		border-radius: 50%;
		padding: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.green-badge {
		background: #4caf50;
	}

	.info-badge {
		background: #2196f3;
	}

	.warning-badge {
		background: #e30886;
	}

	.avatar-container {
		position: relative;
		display: inline-block;
	}

	.bill-button {
		color: #ffffff;

		.bill-icon {
			position: absolute;
			border-radius: 50%;
			background-color: #90cdf4;
			height: 20px;
			min-width: 20px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			top: -1px;
			right: -10px;
		}
		.bill-icon-empty {
			position: absolute;
			border-radius: 50%;
			background-color: transparent;
			height: 20px;
			min-width: 20px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			top: -1px;
			right: -10px;
		}

		&:hover {
			color: #ffffff !important;

			.bill-icon {
				background-color: #45b14b;
			}
		}
	}
}
