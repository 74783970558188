.merchants-container {
	.blue-grad-bg {
		background: var(--blue, linear-gradient(180deg, #00afff 0%, #07f 100%));
	}

	.ant-select-selection {
		background-color: red !important;
	}

	.ant-table-column-sorters {
		display: inline-flex !important;
		flex-grow: 0 !important;
	}

	.ant-table-thead > tr > th {
		background: #f5f5f6 !important;
		border-bottom: 0px !important;
	}

	.search-input {
		display: flex;
		align-items: center;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;

		&:focus {
			box-shadow: none !important;
		}

		input {
			background: #f5f5f5 !important;
			font-size: 12px !important;
			color: #0f2147 !important;

			&::placeholder {
				color: #b7bdc6 !important;
			}
		}
	}

	.ant-select-item-option-selected {
		background-color: rgba(100, 100, 100, 0.2) !important;
	}

	.merchant-initials {
		border: 1px solid rgb(179, 179, 179);
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 14px;
	}
}

.btn-container {
	flex-direction: row;
}

.field-container {
	display: flex;
	flex-direction: row;
}
.country-flag {
	width: 22px;
	margin: 0 5px;
}
.ant-select-selection-item,
.ant-select-item-option-content {
	display: flex;
}
@media (max-width: 1200px) {
	.field-container {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 386px) {
	.btn-container {
		flex-flow: column-reverse;
		align-items: flex-start;
	}

	.form-row {
		flex-flow: column;
		align-items: flex-start;
	}

	.input-field {
		width: 100%;
	}
}
