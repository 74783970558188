.submitted-feedback-container {
	.ant-card-head,
	.ant-card-body {
		padding: 8px 12px !important;
		word-break: break-word;
	}

	.ant-card-head,
	.ant-card-bordered {
		border-color: #dbdbdb !important;
	}

	.feedback-title {
		font-size: 18px;
	}

	.feedback-date {
		font-size: 10px;
		font-weight: 400;
		color: #8b8b8b;
	}
}
