.select-payment-methods {
	border: 1px solid rgba(0, 17, 51, 0.15);
	position: relative;
	margin-bottom: 8px;
}
.select-payment-methods-toggle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 0 8px;
	gap: 20px;

	p {
		margin: 4px 0;
	}
}
.selected-payment-methods-placeholder {
	color: rgba(0, 20, 42, 0.4);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 23px;
}
.payment-methods-down-arrow {
	flex-shrink: 0;
	width: 12px;
	height: 8px;
}
.selected-payment-methods {
	display: flex;
	flex-wrap: wrap;
	gap: 6px;

	.selected-method {
		display: flex;
		height: 40px;
		padding: 12px 15px;
		align-items: center;
		gap: 16px;
		cursor: default;

		span {
			height: 16px;
			width: 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 2px;
		}
	}
}
.payment-methods-dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	background: white;
	padding: 0;
	box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border-top: 1px solid rgba(0, 17, 51, 0.15);
	max-height: 300px;
	overflow-y: auto;
	z-index: 20;

	div {
		padding: 8px;
		color: rgba(0, 13, 29, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}

	.active-method {
		background: #f5f5f5;
	}
}
.open-dropdown {
	border-radius: 8px 8px 0px 0px;
}
.close-dropdown {
	border-radius: 8px;
}

.modal-payment-methods {
	width: 100%;
	margin-bottom: 8px;
}

.payment-methods-label {
	margin: 0 0 4px 0;
	color: #000;
	font-size: 13px;
}
