.upload-file-container {
	display: flex;
	margin: 10px 0;
	.selected-file {
		width: 250px;
		margin-left: 5px;
		border-radius: 0;
		background-color: #eaecef;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border: 2px solid #018aff;
		border-right: none;
	}
}
