@import '../../assets/styles/mixin';

.signin-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 110px);
	overflow: hidden;
	background-size: cover;
	position: relative;
	top: 60px;
	.ant-form-vertical .ant-form-item-label > label {
		height: 30px !important;
	}

	.ltr-input .ant-input {
		direction: ltr;
	}
	.ant-form-item-label {
		color: #0f2147;
		font-size: 12px !important;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding-bottom: 5px !important;
	}

	.ant-input {
		height: 40px !important;
		border-radius: 4px;
		border: 1px solid #d8d8d8;
		background: #f8f8f8;
		color: #0f2147;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;

		&::placeholder {
			color: #757575;
		}
	}

	.ant-input-password {
		padding-block: 0px !important;
		border-radius: 4px;
		border: 1px solid #d8d8d8;
		background: #f8f8f8;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid #d8d8d8;
		background: #f8f8f8;
		color: #0f2147;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		width: 100%;
		direction: ltr;
	}

	.ant-form-item-label > label {
		width: 100%;
	}
}
.form-container {
	border-radius: 10px;
	border: 1px solid #d8d8d8;
	background: #fff;
	padding: 10px 20px;
}
.form-container-heading {
	color: #757575;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 4px;
}
.form-container-subheading {
	color: #0f2147;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 30px;
}

.password-label-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.forgot-password {
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
	color: #00afff;
	cursor: pointer;
}

.form-btn {
	display: flex;
	width: 100%;
	height: 45px;
	padding: 9px 14px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	background: var(--blue, linear-gradient(180deg, #00afff 0%, #07f 100%));
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	box-shadow: none;
	border: none;

	&:disabled {
		background: #f0f0f0;
	}
}
.links {
	color: #0f2147;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;

	.link {
		text-decoration-line: underline;
		color: #00afff;
		font-weight: 600;
	}
}
.warning-note {
	margin-top: 10px;
	margin-bottom: 15px;
	color: #757575;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}
.all-rights-reserved {
	color: #757575;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.ant-checkbox-checked .ant-checkbox-inner {
	direction: ltr !important;
}
