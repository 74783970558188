.order-details-wrapper {
	.ant-alert.ant-alert-info {
		padding: 4px 8px !important;
		margin-bottom: 16px;

		.ant-alert-message {
			margin: 0;
			font-weight: 700;
			color: #0092ff;
		}
	}
}
