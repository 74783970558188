.chatting-with-other-party-container {
	min-width: 250px;
	height: fit-content;
	.open-chat-button {
		border: none;
		width: 45px;
		padding: 0px 5px !important;
		height: 45px;
		position: absolute;
		font-size: 36px;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 25px;
		color: #ffffff;
		cursor: pointer;
		&:hover {
			box-shadow: -3px -3px 3px 3px #ebe9e9;
		}
		@media (max-width: 760px) {
			top: -55px;
		}
		.bill-icon {
			position: absolute;
			border-radius: 50%;
			height: 20px;
			min-width: 20px;
			font-size: 9px;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 4px;
			left: 4px;
		}
		.bill-icon-empty {
			position: absolute;
			border-radius: 50%;
			background-color: transparent;
			height: 20px;
			min-width: 20px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			top: -1px;
			left: -10px;
		}
	}

	.order-life-cycle-chat-container {
		width: 100%;
		max-width: 350px;
		border-radius: 14px;
		border: 1px solid #dbdbdb;
		box-shadow: 5px 6px 10px #b3b3b3;
		background-color: #ffffff;
		z-index: 100;
		position: relative;
		&.hidden {
			visibility: hidden;
		}

		@media (max-width: 575px) {
			position: fixed;
			bottom: 0;
			right: 0;
			max-width: unset;
			margin: 0;
		}

		.close-chat-button {
			position: absolute;
			top: -1px;
			left: -23px;
			height: 48px;
			border: 1px solid #dbdbdb;
			background-color: #f5f5f5;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			cursor: pointer;
			padding: 0 4px;

			&:hover {
				box-shadow: -2px 0px 0px 2px #efefef;
			}
		}

		.order-life-cycle-chat-box-header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			background-color: #f5f5f5;
			padding: 6px 12px;
			border-top-left-radius: 14px;
			border-top-right-radius: 14px;
			.header-container {
				display: flex;
				width: 100%;

				.user-menu-details-initials {
					width: 30px;
					min-width: 30px;
					height: 30px;
					border: 1px solid;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-inline-end: 8px;
					font-weight: 700;
					font-size: 12px;
				}

				.user-details {
					width: 100%;
					.vertical-seperator {
						border: none;
						border-left: 1px solid gray;
						opacity: 0.5;
						height: 18px;
						margin: 0px 5px;
					}
					.live::before,
					.last-seen::before {
						content: '';
						display: inline-block;
						width: 5px;
						height: 5px;
						border-radius: 50%;
						margin-inline: 4px;
						margin-bottom: 2px;
					}

					.live {
						color: #2bc72b;
						font-weight: 500;

						&::before {
							background-color: #2bc72b;
						}
					}

					.last-seen {
						color: #acb8b1;

						&::before {
							background-color: #acb8b1;
						}
					}

					.user-name {
						font-weight: 700;
						font-size: 12px;
					}

					.user-info-container {
						font-size: 10px;

						p {
							margin: 0 4px;
							color: #737e8d;

							&:first-of-type {
								margin-left: 0;
							}
						}
					}
				}

				.user-info-container {
					display: flex;
				}
			}
		}

		.order-life-cycle-chat-box-body {
			height: 100%;
			max-height: 330px;
			min-height: 330px;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 10px;
			font-size: 13px;
			.chat-message-container {
				display: flex;
				flex-direction: column;
				width: 100%;
				margin-bottom: 12px;
				align-items: center;

				@media (min-width: 575px) {
					min-width: 308px;
				}

				p {
					padding: 5px 10px;
					border-radius: 12px;
					width: fit-content;
					max-width: calc(100% - 30px);
					overflow-wrap: break-word;
				}

				.chat-card {
					.ant-card-body {
						padding: 1px 0;
					}
					p {
						padding: 0px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						max-width: unset;
						display: flex;
						align-items: center;
					}
				}
				button {
					border: none;
					padding: 0;
					border-radius: 8px;
					max-width: 80%;
					cursor: pointer;
					color: #0095ff;
					background: none;
					padding-bottom: 4px;
				}

				.time {
					font-size: 10px;
					color: #8f8f8f;

					&:after {
						position: relative;
						display: inline-block;
						top: 2px;
						margin: 0 6px;
					}

					&.received:after {
						content: '\2714\2714';
					}

					&.seen:after {
						content: '\2714\2714';
						color: #33a867;
						font-size: 10px;
					}
				}

				&.mine {
					align-items: end;

					p {
						color: #ffffff;
						background-color: #0f2147;
						border-bottom-right-radius: 0;
					}
				}

				&.theirs {
					align-items: start;

					p {
						color: #00142a;
						background-color: #ebebeb;
						border-bottom-left-radius: 0;
					}

					.time-text {
						align-self: end;
					}

					.time::after {
						color: transparent;
					}
				}

				&.admin {
					align-items: center;

					p {
						color: #ffffff;
						background-color: #33a867;
					}
				}
			}
		}

		.order-life-cycle-chat-box-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 10px;
			border-top: 1px solid #dbdbdb;
			height: auto;
			label,
			button {
				margin: 0;
				padding: 0;
				border: none;
				border-radius: 50%;
				cursor: pointer;
				background-color: transparent;
				.anticon:hover {
					box-shadow: 0 0 4px 4px #cacdff;
				}

				&:has(> .ant-input-disabled),
				&:disabled {
					cursor: no-drop;
					filter: grayscale(100%);

					.anticon:hover {
						box-shadow: none;
					}
				}
			}

			.chat-form {
				display: flex;
				justify-content: space-between;
				width: 100%;

				.ant-form-item {
					margin: 0 !important;
					width: calc(100% - 75px);
				}

				textarea {
					border: 1px solid #b4b4b4;
					border-radius: 4px;
					height: 28px;
					position: relative;
				}
				.custom-icon {
					position: absolute;
					top: 95%;
					right: 60px;
					transform: translateY(-50%);
				}
				.emoji-picker {
					position: absolute;
					bottom: 30px;
					right: 0;
				}
			}
		}
	}

	input.hide {
		display: none !important;
	}
}
