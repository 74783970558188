.feedback-form {
	position: relative;
	border: 1px solid #dbdbdb;
	border-radius: 8px;
	padding: 10px;
	background-color: #eaecef;
	.feedBack-icon {
		position: absolute;
		right: 10px;
		z-index: 100;

		&.ant-switch-checked {
			background: #33a867 !important;
		}
		&:dir(rtl) {
			left: 10px !important;
			right: unset;
		}
	}

	.ant-switch-inner-checked,
	.ant-switch-inner-unchecked {
		font-size: 16px !important;
	}

	.ant-form-item {
		margin-bottom: 8px !important;
	}
}
