.actions-corner-container {
	.buttons-container {
		display: flex;
		justify-content: end;
		flex-wrap: wrap;
		margin-bottom: 10px;

		button {
			background: none;
			border: none;
			border-radius: 4px;
			padding: 8px;
			margin: 4px;
			border: 1px solid;
			cursor: pointer;

			&:hover {
				box-shadow: 4px 4px 10px #a9a9a9;
			}

			&.cancel-order {
				border-color: #0f2147;
			}

			&.reorder,
			&.payment-received,
			&.payment-completed,
			&.finished-successfully {
				background-color: #00afff;
				color: #ffffff;
				border-color: #00afff;
			}

			&.appeal {
				background-color: #fcd632;
				border-color: #fcd632;
			}

			&.accept {
				background-color: #33a867;
				border-color: #33a867;
				color: #fff;
			}

			&.reject {
				background-color: #f6465d;
				border-color: #f6465d;
				color: #fff;
			}

			&:disabled {
				cursor: not-allowed;
				border-color: #d9d9d9;
				color: rgba(0, 0, 0, 0.25);
				box-shadow: none;
				background: #f0f0f0;
			}
		}
	}

	.ant-alert-description {
		ul {
			padding: 0 !important;
		}
	}
}
