.footer {
	.footer-container {
		display: flex;
		padding: 20px 60px;

		@media (max-width: 640px) {
			padding: 20px;
		}

		.item-start {
			margin: 0 8px 0 0;

			&:hover {
				color: #00afff;
			}
		}
	}

	.footer-tail {
		padding: 30px 60px;

		@media (max-width: 640px) {
			padding: 20px;
		}
	}
}
