@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	height: 100vh;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 1rem;
	line-height: 1.58;
	color: #333;
	background-color: #f4f4f4;
}
#root {
	height: 100%;
}
.ant-notification-notice {
	padding: 2px 8px 12px 8px !important;
	font-size: 13px !important;
}
.ant-notification-notice-close {
	top: 10px !important;
	right: 5px !important;
	&:dir(rtl) {
		left: 10px !important;
	}
}
.ant-notification-notice-icon {
	top: 11px !important;
}
.ant-pagination-options {
	display: none !important;
}
.ant-popover .ant-btn {
	background-color: transparent !important;
}
.ant-notification-notice-description {
	padding-right: 20px !important;
}

:where(.css-dev-only-do-not-override-1yvripe).ant-input-disabled,
:where(.css-dev-only-do-not-override-1yvripe).ant-input[disabled] {
	color: rgb(0 0 0 / 69%) !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	border: 1px solid rgb(36, 109, 27) !important;
	background-color: #ffffff;
}

.ant-skeleton-paragraph {
	margin-block-start: 12px !important;
}

.ant-alert-info {
	padding: 8px !important;
}

#tidio-chat-iframe {
	bottom: 30px !important;
}

.highlighted-row {
	background-color: #f2fbff;
}
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
	outline: none !important;
}

.rtl {
	font-family: 'Cairo', sans-serif;
	font-optical-sizing: auto;
	font-weight: 100;
	font-style: normal;
	font-weight: 400;
	font-variation-settings: 'slnt' 0;
	vertical-align: middle;
}
.rtl [class^='ant-'],
.rtl [class*=' ant-'] {
	font-family: 'Cairo', sans-serif !important;
}

.hide-table-header .ant-table-thead {
	display: none; /* Hides the header row */
}

.hide-table-header .ant-table-cell {
	border-top: none; /* Optional: Adjust cell styling for better visuals */
}
.hide-table-header.ant-table-wrapper .ant-table-tbody > tr > td {
	padding: 5px !important;
}
.hide-table-header.ant-table-wrapper .ant-table-tbody > tr > td:first-child {
	padding-top: 0px !important;
}
.ant-collapse-header {
	padding: 5px 10px !important;
}
/* Ensure the date picker dropdown is responsive */
@media (max-width: 480px) {
	.ant-picker-panel-container .ant-picker-panels {
		display: flex;
		flex-direction: column;
	}
}

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.rtl button {
	font-family: 'Cairo', sans-serif !important;
}

.ant-table-wrapper {
}
