@import '../../assets/styles/variables';

.contact-support-layout {
	min-height: 100vh;
}
.contact-support-container {
	display: flex;
	justify-content: center;
	margin-top: 100px;
	padding: 0 20px;

	.contact-support-form {
		width: 100%;
		max-width: 800px;

		.row-fields {
			display: flex;
			justify-content: space-between;

			@media #{$media-xs, $media-sm} {
				flex-direction: column;
			}

			.ant-form-item {
				width: calc(50% - 20px);

				@media #{$media-xs, $media-sm} {
					width: 100%;
				}
			}
		}
	}
}
