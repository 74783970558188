.button-container {
	display: flex;
	justify-content: end;
}

@media (min-width: 993px) {
	.small-screen-row {
		display: none;
	}
}

@media (min-width: 993px) {
	.merchant-table-col {
		flex-direction: row;
	}
}

@media (max-width: 404px) {
	.merchant-table-col {
		flex-direction: column;
		padding: 10px;
	}

	.button-container {
		justify-content: start;
	}
}
.merchantTableProfile {
	.status {
		position: relative;
	}
	.status::after {
		content: '';
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		margin-inline: 4px;
		margin-bottom: 2px;
		position: absolute;
		top: 23px;
		right: -2px;
	}

	.online {
		&::after {
			background-color: #2bc72b;
		}
	}
	.offline {
		&::after {
			background-color: red;
		}
	}
}
