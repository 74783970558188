@import '../../assets/styles/mixin';

.splide__arrows--rtl .splide__arrow--prev {
	left: unset;
	right: unset;
}

.splide__arrows--rtl .splide__arrow--next {
	left: unset;
	right: unset;
}

.single-merchant {
	padding: 20px;
	max-width: 1200px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: auto;

	.header {
		background-color: white;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.section-container {
		display: flex;
		justify-content: center;
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.section {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;

		&:first-of-type {
			@media (max-width: 768px) {
				margin-bottom: 10px;
				width: 100%;
			}
		}

		@media (max-width: 768px) {
			border: 1px solid #b3b3b3;
			border-radius: 6px;
			width: 100%;
		}
	}

	.horizontal-separator {
		border: 0.5px solid #b3b3b3;
		width: 70%;
	}

	.full-width-separator {
		margin: 0 2px;
		width: 100%;
		max-width: 800px;

		@media (min-width: 768px) {
			border: 0.5px solid #b3b3b3;
		}
	}

	.profile-icon {
		border: 2px solid #b3b3b3;
		border-radius: 900px;
		width: 80px;
		height: 80px;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.profile-icon-text {
		font-size: x-large;
		color: #333333;
		font-weight: bold;
	}

	.center-container {
		justify-content: center;
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.text-card {
		flex-direction: column;
		justify-content: flex-start;
		display: flex;
		width: 100%;
		padding: 0 20px 20px 20px;

		p {
			word-break: break-word;
		}
	}

	.text-container {
		width: 100%;
		justify-content: space-between;
		display: flex;
		margin-top: 8px;
	}

	.heading {
		font-size: 20px;
		color: #000000;
		font-weight: 600;
		margin-bottom: 0;
	}

	.info {
		font-size: 16px;
		color: #333333;
		font-weight: 400;
	}

	.content {
		font-size: 16px;
		color: black;
		font-weight: 500;
	}

	.terms {
		font-size: 16px;
		color: #6f6e6e;
		font-weight: 300;
	}

	.review-container {
		width: 100%;
		justify-content: flex-start;
		padding: 20px;
	}

	.merchant-initials {
		border: 1px solid rgb(179, 179, 179);
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 14px;
	}
	.review-heading {
		color: #333333;
		font-weight: 600;
		margin: 20px 30px;
	}

	.review-card {
		padding: 30px;
		border-radius: 10px;
		border: 2px solid #b3b3b3;
		width: 25vw;
		margin: 10px;
	}

	.profile-picture {
		border-radius: 100px;
		margin-right: 30px;
	}

	.review-info-container {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	.review-text {
		font-size: 14px;
		color: #6f6e6e;
	}

	.row-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.splide__pagination {
		display: none !important;
	}

	@media (max-width: 768px) {
		.horizontal-separator {
			display: none;
		}
	}

	.reviews-section {
		width: 100%;
	}

	.single-review-container {
		border: 1px solid #b3b3b3;
		border-radius: 10px;
		margin-right: 20px;
		padding: 12px;
		max-height: 160px;
		height: 100%;
		overflow-y: auto;
		.single-review-header {
			display: flex;
			align-items: center;

			h4 {
				margin: 0;
			}
			p {
				margin: 0;
			}
		}
	}

	.name-initials {
		border: 1px solid #b3b3b3;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin: 0;
		padding: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
