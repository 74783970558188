.marquee {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	align-items: center;
	white-space: nowrap;
	position: relative;
}
.no-marquee {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	align-items: center;
	white-space: nowrap;
	position: relative;
	justify-content: center;
}
.marquee p {
	display: inline-block;
	padding-left: 100%;
	animation: marquee 30s linear infinite;
}
@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}
