.body-details-wrapper {
	width: 100%;
	max-width: 350px;
	padding-bottom: 50px;
	.close-modal-icon {
		width: 14px;
		height: 14px;
		cursor: pointer;
	}

	@media (max-width: 700px) {
		max-width: 200px;
	}

	@media (max-width: 575px) {
		min-width: 100%;
	}

	.order-details-wrapper {
		border-bottom: 1px solid #dbdbdb;
		margin-bottom: 16px;
		padding: 0 0 8px 0;

		.details-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 4px;

			.value {
				font-weight: 700;
			}
		}
	}
}

.uploadedFile {
	border: 1px solid #018aff;
	width: 125px;
	height: 30px;
	background-color: #eaecef;
	color: #018aff;
	cursor: pointer;
	padding: 1px 5px;
}
