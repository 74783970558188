.order-life-cycle-stepper-container {
	@media (max-width: 700px) {
		margin-bottom: 16px;
	}

	.ant-steps-item-tail::after {
		background-color: #dbdbdb !important;
	}

	.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
		color: transparent !important;
		padding: 0 !important;

		&::after {
			background-color: #dbdbdb !important;
		}
	}
	.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
		@media (min-width: 576px) {
			left: 0px;
			position: relative;
			top: 4px;
			font-size: 13px !important;
			width: 140px !important;
			padding-bottom: 10px;
		}
	}

	.ant-steps-item-icon {
		line-height: 22px !important;
		border-color: #00afff !important;
		background: linear-gradient(#00afff, #0077ff) !important;
	}

	.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
		background: linear-gradient(#dddddd, #dbdbdb) !important;
		border-color: #dddddd !important;
	}

	.ant-steps .ant-steps-label-horizontal {
		width: 400px;
	}

	.ant-steps-item-title {
		@media (max-width: 576px) {
			line-height: 0 !important;
		}

		&::after {
			background-color: #dbdbdb !important;
		}
	}

	.anticon svg {
		fill: #ffffff !important;
	}
	.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
		border: 1px dotted grey !important;
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
		border: 1px solid #0094ff !important;
	}
	.number {
		color: #ffffff;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
