.order-life-cycle-state-main-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #ffffff;

	.order-life-cycle-state-wrapper {
		max-width: 1200px;
		width: 100%;
		padding: 20px 20px 10px 20px;
		display: flex;
		justify-content: space-between;

		@media (max-width: 700px) {
			flex-direction: column;
		}
	}
}
