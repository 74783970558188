@import '../../assets/styles/variables';

.merchant-buy-sell-modal .ant-modal-content {
	padding: 0 !important; /* Adjust this to control padding as needed */
}
.ant-modal .ant-modal-content {
	overflow: hidden;
}
.modal-container {
	display: flex;
	flex-direction: row;
	border-radius: 10px;
	@media (max-width: 700px) {
		min-width: unset;
	}
}

.model-info {
	background: #0f2147;
	padding: 20px;
	width: 50%;
	min-width: 50%;
	@media (max-width: 700px) {
		width: 100%;
		min-width: 100%;
	}
}

.merchant-info {
	display: flex;
	margin-bottom: 16px;
}

.model-info-ar {
	background: #0f2147;
	padding: 20px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	width: 50%;
	min-width: 50%;
	@media (max-width: 700px) {
		width: 100%;
		min-width: 100%;
	}
}

.modal-merchant-initials {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	border: 1px solid #ffffff;
	border-radius: 50%;
	color: #fff;
}

.margin-left-2 {
	margin-left: 16px;
}

.margin-right-2 {
	margin-right: 16px;
}

.merchant-name {
	color: #fff;
	font-weight: 600;
	margin: 0;
	font-size: 16px;
}

.merchant-order {
	color: #fff;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	display: flex;
	align-items: center;
}

.vr {
	height: 13px;
	width: 2px;
	background: rgba(255, 255, 255, 0.6);
	margin: 0 15px;
}

.rating {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	gap: 6px;
	margin: 0;
}

.rating-icon {
	height: 16px;
	width: 16px;
}

.order-info {
	display: flex;
	width: 100%;
	flex-direction: column;

	.order-info-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;

		span {
			color: #fff;
			font-style: normal;
			font-weight: 400;

			&:last-child {
				color: #fff;
				text-align: right;
				font-style: normal;
				font-weight: 600;
			}
		}
	}
}

.hr {
	height: 1px;
	width: 100%;
	background: rgba(255, 255, 255, 0.6);
	margin-block: 16px;
}

.terms-and-conditions {
	.tac-heading {
		color: #fff;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		margin: 0;
	}

	ul {
		list-style-type: disc !important;
		padding-left: 20px;

		li {
			list-style: disc !important;
			color: #fff;
			font-style: normal;
			font-weight: 400;
		}
	}
}

.form {
	padding: 20px;
	background-color: #ffffff;
}

.unit-price {
	color: #222223;
	font-style: normal;
	font-weight: 500;

	span {
		font-weight: 700;
	}
}

.you-buy-or-sell-error {
	color: red;
	font-size: 12px;
	margin-top: -18px;
	position: absolute;
	display: block;
}

.you-buy-or-sell {
	border-radius: 8px;
	border: 1px solid rgba(0, 17, 51, 0.15);
	background: #fff;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: all 300ms;
	margin-bottom: 5px;
	width: 100%;

	&:hover {
		border: 1px solid rgba(0, 17, 51, 0.75);
	}

	&.disabled:hover {
		border-color: rgba(0, 17, 51, 0.15);
	}

	.you-buy-or-sell-input-container {
		p {
			color: #001529;
			font-style: normal;
			font-weight: 500;
			margin: 2px 0;
			width: 100%;
		}

		input {
			color: #001529;
			font-style: normal;
			font-weight: 700;
			outline: none;
			border: none;
			margin-bottom: 6px;
			width: 100%;

			&::placeholder {
				color: #dbdbdb;
			}
		}

		h6 {
			font-style: normal;
			font-weight: 700;
			outline: none;
			color: #dbdbdb;
		}

		.active {
			color: #001529;
		}
	}

	.you-buy-or-sell-icon {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		gap: 8px;

		img {
			flex-shrink: 0;
			width: 25px;
			height: 25px;
		}

		span {
			flex-shrink: 0;
			color: #001529;
			font-style: normal;
			font-weight: 700;
		}
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.payment-note {
	margin-bottom: 20px;

	p {
		color: #001529;
		font-style: normal;
		font-weight: 400;
		margin: 0 0 4px 0;
		font-size: 13px;
	}

	textarea {
		width: 100%;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid rgba(0, 17, 51, 0.15);
		background: #fff;
		outline: none;
		font-style: normal;
		font-weight: 400;
		resize: none;
	}
}

.note {
	display: flex;
	gap: 8px;

	img {
		width: 14px;
		height: 14px;
	}

	p {
		color: rgba(0, 20, 42, 0.4);
		font-style: normal;
		font-weight: 400;
		margin-top: -3px;
		font-size: 12px;
	}
}

.main-btn {
	border-radius: 5px;
	background: var(--blue, linear-gradient(180deg, #00afff 0, #07f 100%));
	padding: 8px;
	width: 100%;
	color: #fff;
	text-align: center;
	font-style: normal;
	font-weight: 700;
	border: none;
	cursor: pointer;

	&:hover {
		background: var(--blue, linear-gradient(180deg, #00afff 0, rgb(0, 88, 189) 100%));
	}

	&:disabled {
		cursor: not-allowed;
		border-color: #d9d9d9;
		color: rgba(0, 0, 0, 0.25);
		box-shadow: none;
		background: #f0f0f0;
	}

	& > span {
		width: 100%;
		height: 100%;
		display: block;
	}
}

.close-modal-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
	@media (max-width: 700px) {
		color: white;
	}
}

@media (max-width: 500px) {
	.modal-container {
		flex-direction: column;
	}

	.modal {
		position: relative;
		top: 0;
		height: 90vh;
		overflow: scroll;
	}

	.model-info {
		width: 100%;
		min-width: 100%;
	}
}
