@import '../../assets/styles/mixin';
@import '../../assets/styles/variables';

.my-offers-container {
	.ant-form-item {
		margin-bottom: 8px;
	}
	.ant-card-body {
		padding: 4px 16px;
	}
	.ant-card-head-title {
		color: #00afff;
		font-weight: 700;
		font-size: 20px;
	}
	.ant-card-head {
		padding: 8px 16px;
		min-height: 44px;
		border: 0;
	}

	.my-offers-forms-continer {
		display: flex;
		justify-content: center;
		padding: 20px 0;

		@media #{$media-xs, $media-sm, $media-md} {
			flex-direction: column;
			align-items: center;
			padding: 10px;
		}

		& > div {
			width: 100%;
			max-width: 450px;
		}
	}

	.my-offers-form-content {
		display: flex;
		justify-content: start;
		flex-wrap: wrap;

		& > div {
			@media #{$media-lg, $media-xl} {
				width: 400px;
			}

			@media #{$media-xs, $media-sm, $media-md} {
				width: 100%;
			}
		}
	}
}
