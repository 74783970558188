.payment-receiving-dialog-list {
	padding: 0px;
	& .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #0094ff;
		border-color: #0094ff;
	}
	& .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #0094ff !important;
		border-color: #0094ff !important;
	}
}
.otp-digit {
	margin-right: 1rem;
}
.otp-digit:last-child {
	margin-right: 0px;
}
