.order-life-cycle-body-main-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #ffffff;

	.order-life-cycle-body-wrapper {
		position: relative;
		max-width: 1200px;
		min-height: 444px;
		width: 100%;
		padding: 0 20px 20px 20px;
		display: flex;
		justify-content: space-between;
		@media (max-width: 760px) {
			flex-direction: column;
		}
	}
}
