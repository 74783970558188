.progress-container {
	width: 200px;
	height: 20px;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 10px;
	position: relative;
}

.progress-bar {
	width: 0%;
	height: 100%;
	background-color: #fff;
	border-radius: 10px;
	animation: progress 5s linear infinite;
}

@keyframes progress {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}
